/** @jsx jsx */
import React, { Fragment } from "react";
import { Container, Row, Col } from "react-bootstrap"
import { jsx } from '@emotion/react'
import Img1 from '../assets/images/lp/unidades/foz/more/1.png'
import Img2 from '../assets/images/lp/unidades/foz/more/2.png'
import Img3 from '../assets/images/lp/unidades/foz/more/3.png'
import Img4 from '../assets/images/lp/unidades/foz/more/4.png'
import Img5 from '../assets/images/lp/unidades/foz/more/5.png'
import Img6 from '../assets/images/lp/unidades/foz/more/6.png'
import IconBack from '../assets/images/lp/unidades/icons/back-to.svg'

import { 
  headerMoreImages,
  noPr,
  mostrarFotos,
}  from '../assets/styles/UnidadesBase.styles'

const UnidadesBasePage = () => {
  return (
    <Fragment>
      <section css={headerMoreImages}>
        <Container>
          <Row>
            <Col>
              <a href='/unidades'><img src={IconBack} style={{ width: 'auto', marginBottom: 32 }} /></a>
            </Col>
          </Row>
          <Row>
            <Col lg={12} md={12} sm={12}>
              <img src={Img1} />
            </Col>
          </Row>
          <Row>
            <Col lg={6} md={6} sm={12} css={noPr}>
              <img src={Img2} />
            </Col>
            <Col lg={6} md={6} sm={12}>
              <img src={Img3} />
            </Col>
          </Row>
          <Row>
            <Col lg={12} md={12} sm={12}>
              <img src={Img4} />
            </Col>
          </Row>
          <Row>
            <Col lg={6} md={6} sm={12} css={noPr}>
              <img src={Img5} />
            </Col>
            <Col lg={6} md={6} sm={12}>
              <img src={Img6} />
            </Col>
          </Row>
        </Container>
      </section>
    </Fragment>
  );
};

export default UnidadesBasePage;
